import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Introduce = () => {
  const { t } = useTranslation();
  const nav = useNavigate();

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
      className={`flex flex-col pb-20`}
    >
      <div className="main-container mb-12">
        <div className="py-5 border-[#D3D5D7] border-b-2">
          <span
            className="cursor-pointer text-link"
            onClick={() => {
              nav("/");
            }}
          >
            {t("home")}
          </span>
          {" >"} {t("about")}
        </div>
        <div className="relative mt-10 introduce-container">
          <div className="text-xl font-inter-bold uppercase">{t("about")}</div>
          <div className="mt-4">{t("introduce_1")}</div>
          <div>{t("introduce_2")}</div>
          <div className="typo-xl mt-5">{t("introduce_3")}</div>
          <div className="typo-l">{t("introduce_4")}</div>
          <div>{t("introduce_5")}</div>
          <div className="flex gap-4 img-container">
            <img
              src="/assets/introduce/1.webp"
              alt="demo"
              className="fleximg"
            />
            <img
              src="/assets/introduce/15.webp"
              alt="demo"
              className="fleximg"
            />
          </div>
          <div>{t("introduce_6")}</div>

          <div className="flex gap-4 img-container">
            <img
              src="/assets/introduce/16.webp"
              alt="demo"
              className="fleximg"
            />
            <img
              src="/assets/introduce/17.webp"
              alt="demo"
              className="fleximg"
            />
            {/* <img
              src="/assets/introduce/4.webp"
              alt="demo"
              className="fleximg"
            /> */}
          </div>

          <div>{t("introduce_7")}</div>

          <div>
            <li>{t("introduce_8")}</li>
            <li>{t("introduce_9")}</li>
            <li>{t("introduce_10")}</li>
            <li>{t("introduce_11")}</li>
            <li>{t("introduce_12")}</li>
          </div>

          <div className="flex gap-4 img-container">
            <img
              src="/assets/introduce/5.webp"
              alt="demo"
              className="fleximg"
            />
            <img
              src="/assets/introduce/4.webp"
              alt="demo"
              className="fleximg"
            />
          </div>

          <div>{t("introduce_13")}</div>

          <div>{t("introduce_14")}</div>

          <div className="typo-l">{t("introduce_15")}</div>
          <div className="flex gap-4 img-container">
            <img src="/assets/introduce/18.webp" alt="demo" />
            <img src="/assets/introduce/19.webp" alt="demo" />
          </div>

          <div>{t("introduce_16")}</div>

          <div className="typo-xl">{t("introduce_17")}</div>

          <div>{t("introduce_18")}</div>

          <div className="flex gap-4 img-container">
            <img
              src="/assets/introduce/9.webp"
              alt="demo"
              className="fleximg"
            />
            <img
              src="/assets/introduce/10.webp"
              alt="demo"
              className="fleximg"
            />
            <img
              src="/assets/introduce/11.webp"
              alt="demo"
              className="fleximg"
            />
          </div>
          <div className="typo-l">{t("introduce_19")}</div>
          <div>{t("introduce_20")}</div>
          <div>{t("introduce_21")}</div>
          <div className="typo-l">{t("introduce_22")}</div>
          <div>{t("introduce_23")}</div>
          <div>{t("introduce_24")}</div>
          <div className="typo-l">{t("introduce_25")}</div>
          <div>{t("introduce_26")}</div>
          <div className="typo-l">{t("introduce_27")}</div>
          <div>{t("introduce_28")}</div>
          <div className="imgonly">
            <img src="/assets/introduce/8.webp" alt="demo" />
          </div>
          <div className="typo-l">{t("introduce_29")}</div>
          <li>{t("introduce_30")}</li>
          <li>{t("introduce_31")}</li>
          <li>{t("introduce_32")}</li>
          <li>{t("introduce_33")}</li>
          <li>{t("introduce_34")}</li>
          <li>{t("introduce_35")}</li>
          <li>{t("introduce_36")}</li>
          <li>{t("introduce_37")}</li>
          <li>{t("introduce_38")}</li>
          <li>{t("introduce_39")}</li>
          <li>{t("introduce_40")}</li>
          <li>{t("introduce_41")}</li>
          <li>{t("introduce_42")}</li>
        </div>
      </div>
    </div>
  );
};
