import { useEffect, useState } from "react";

export default function ScrollFloat() {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    function onScroll() {
      let distance = window.scrollY;
      if (distance > 400 && !isShow) {
        setIsShow(true);
      } else if (distance <= 400) {
        setIsShow(false);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  if (isShow)
    return (
      <div
        className="fixed bottom-10 right-10 w-[80px] h-[50px] cursor-bottom"
        style={{ zIndex: 1000 }}
      >
        <div
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth", // for smoothly scrolling
            });
          }}
          className="w-full h-full relative justify-center items-center cursor-pointer float-anim rounded-xl"
        >
          <img
            src="/assets/icon/circled-up-2.webp"
            alt="up"
            className="self-center mx-auto h-full"
          />
          <div className="font-inter-bold text-sm text-center text-red-400">
            Scroll to top
          </div>
        </div>
      </div>
    );

  return <div></div>;
}
