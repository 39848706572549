import { useState, useEffect } from "react";
import Header from "layout/Header";
import "./index.css";
import Main from "layout/Main";
import Footer from "layout/Footer";
import Loading from "./components/Loading";
import i18n from "i18n";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "layout/ScrollToTop";
import { ProductList } from "layout/ProductList";
import { NewList } from "layout/NewList";
import ScrollFloat from "components/ScrollFloat";
import { Contact } from "layout/Contact";
import { Introduce } from "layout/Introduce";

const MAX_LOADING = 0;
let currentLoad = 0;
let time = null;

export function Layout() {
  const [percent, setPercent] = useState(0);
  const [story, setStory] = useState(false);
  const [hiddenHeader, setHiddenHeader] = useState(false);
  const [language, setLanguage] = useState(i18n.language);

  function increaseLoading() {
    currentLoad++;
  }
  useEffect(() => {
    time = setInterval(() => {
      let percent = currentLoad / MAX_LOADING;
      setPercent(percent);
      if (percent === 1) {
        clearInterval(time);
        time = null;
      }
    }, 500);
  }, []);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const [selectedData, setSelectedData] = useState({});

  return (
    <>
      {percent < 1 && <Loading percent={percent} />}
      <ScrollFloat />
      <div className="relative w-full overflow-x-hidden" style={{}}>
        <div className="flex flex-col mx-auto">
          <div className="w-full flex justify-center">
            {!hiddenHeader && (
              <Header
                onScrollStory={(sr) => {
                  setStory(sr);
                }}
                language={language}
                setLanguage={setLanguage}
              />
            )}
          </div>

          <div>
            <ScrollToTop>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Main
                      increaseLoading={increaseLoading}
                      showStory={story}
                      setHiddenHeader={setHiddenHeader}
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                      language={language}
                    />
                  }
                ></Route>
                <Route
                  path="/:optical"
                  element={
                    <Main
                      increaseLoading={increaseLoading}
                      showStory={story}
                      setHiddenHeader={setHiddenHeader}
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                      language={language}
                    />
                  }
                ></Route>
                <Route path="/about" element={<Introduce />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/product" element={<ProductList />}></Route>
                <Route path="/news/:id" element={<NewList />}></Route>
                <Route path="/news" element={<NewList />}></Route>
              </Routes>
            </ScrollToTop>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
