import { Category } from "layout/ProductList";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
const category = require("../data/category.json");
const products = require("../data/product.json");

export const Section3 = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [currentCate, setCate] = useState(category[0].category);
  const [smallCate, setSmallCate] = useState(null);
  const data = ["1.webp", "2.webp"];

  useEffect(() => {
    function onScroll() {
      let distance = window.scrollY;
      if (distance > 0 && !isShow) {
        setIsShow(true);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="h-full relative bg-white">
      <div className="relative main-container grid md:grid-cols-4 gap-12">
        <div className="hidden md:block col-span-1 mt-4">
          <div className="h-full bg-[#f5f5f5]">
            <div className="text-lg bg-[#446cb3] p-4 text-white uppercase">
              {t("Product list")}
            </div>
            <Category
              currentCate={currentCate}
              setCate={setCate}
              smallCate={smallCate}
              setSmallCate={setSmallCate}
              isShowActive={false}
              isNav={true}
            />
          </div>
        </div>
        <div className="relative col-span-3 mt-4">
          <div className="grid md:grid-cols-3 gap-4">
            <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              showIndicators={true}
              showArrows={false}
              showStatus={false}
              // centerMode
              emulateTouch
              swipeable
              interval={3000}
              className="w-full col-span-2 md:h-[340px] bg-black"
            >
              {data.map((image, idx) => {
                return (
                  <div key={`image-${idx}`}>
                    <img
                      alt=""
                      src={`/assets/banner/${image}`}
                      className="w-full h-full object-contain"
                    />
                  </div>
                );
              })}
            </Carousel>
            <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              showIndicators={true}
              showArrows={false}
              showStatus={false}
              // centerMode
              emulateTouch
              swipeable
              interval={3000}
              className="hidden md:block w-full col-span-1 h-[340px] bg-demo"
            >
              {["demo", "anode_demo_2", "eye_2", "anode_demo_3"].map(
                (image, idx) => {
                  return (
                    <div key={`image-${idx}`} className="bg-demo-child">
                      <img
                        alt=""
                        src={`/assets/product/${image}.webp`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  );
                }
              )}
            </Carousel>
          </div>

          <div className="text-xl w-full text-left font-inter-bold my-4 text-[#446CB3] uppercase">
            {t("Anode Products")}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {[
              "anode_demo_1",
              "5g_jack_0",
              "5g2_jack_2",
              "anode_demo_4",
              "anode_demo_5",
              "anode_demo_6",
              "anode_demo_7",
              "pluge_frame_1",
            ].map((i, idx) => {
              return (
                <div>
                  <div
                    className="bg-white border-[1px] rounded-lg w-full relative product-container"
                    style={{
                      paddingTop: "50%",
                      paddingBottom: "50%",
                      height: 0,
                    }}
                    key={`image-id4-${idx}`}
                  >
                    <img
                      src={`/assets/product/${i}.webp`}
                      alt="product"
                      className="w-full h-full product-img rounded-lg"
                    />
                  </div>
                  <div className="truncate text-center font-inter-bold">
                    {i.includes("anode_demo")
                      ? t("Anode sample")
                      : t(
                          i
                            .split("_")
                            .slice(0, i.split("_").length - 1)
                            .join("_") + "_title"
                        )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-xl w-full text-left font-inter-bold my-4 text-[#446CB3] uppercase mt-10">
            {t("Precision mechanical assembies")}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {[
              "guider_spring_0",
              "sleeve_1",
              "eye_2",
              "truc_bom_3",
              "insert_0",
              "ong_sao_1",
              "rocket_box_bold_cover_2",
              "under_0",
            ].map((i, idx) => {
              return (
                <div>
                  <div
                    className="bg-white border-[1px] rounded-lg w-full relative product-container"
                    style={{
                      paddingTop: "50%",
                      paddingBottom: "50%",
                      height: 0,
                    }}
                    key={`image-id4-${idx}`}
                  >
                    <img
                      src={`/assets/product/${i}.webp`}
                      alt="product"
                      className="w-full h-full product-img rounded-lg"
                    />
                  </div>
                  <div className="truncate text-center font-inter-bold">
                    {t(
                      i
                        .split("_")
                        .slice(0, i.split("_").length - 1)
                        .join("_") + "_title"
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
