import { useTranslation } from "react-i18next";

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <div className="relative bg-[#111828]">
      <div className="p-8 main-container">
        <div className="lg:flex lg:flex-wrap gap-10 justify-between items-center w-full relative bg-footerbg bg-cover">
          <div className="text-white font-inter mt-10 lg:mt-0 max-w-[640px]">
            <div className="gap-2 my-1 flex">
              <img
                src="/assets/icon/gps.webp"
                alt="location"
                className="h-8 inline-block mb-2"
              />
              <div className="inline-block self-center">
                {t("First factory")} ({t("Headquarters")}): {t("location_1")}
              </div>
            </div>
            <div className="gap-2 my-1 flex">
              <img
                src="/assets/icon/gps.webp"
                alt="location"
                className="h-8 inline-block mb-2"
              />
              <div className="inline-block self-center">
                {t("Second factory")}: {t("location_2")}
              </div>
            </div>
            <div className="gap-2 my-1 mt-2 mb-3 flex sm:block">
              <img
                src="/assets/icon/phone.webp"
                alt="location"
                className="h-7 inline-block md:mr-2"
              />
              <div className="inline-block capitalize">
                {t("contact")}: (+84) 913.300.803 - (+84) 912.710.480
              </div>
            </div>
            <div className="gap-2 my-1 flex">
              <img
                src="/assets/icon/mail.webp"
                alt="location"
                className="h-5 ml-1 inline-block"
              />
              <div className="inline-block self-center">
                Email: tanviet.cpy@gmail.com
              </div>
            </div>
          </div>

          <div className="z-20 mt-1 xl:mt-0 flex-col md:flex-row flex gap-5">
            <div className="w-full">
              <div>
                <iframe
                  title="tanviet"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=5VC3+P42%20C%C3%B4ng%20ty%20CP%20v%C3%A0%20TM%20c%C6%A1%20kh%C3%AD%20T%C3%A2n%20Vi%E1%BB%87t,%20%C4%90%C3%B4ng%20Anh,%20H%C3%A0%20N%E1%BB%99i,%20Vietnam+(C%C3%B4ng%20ty%20CP%20v%C3%A0%20TM%20c%C6%A1%20kh%C3%AD%20T%C3%A2n%20Vi%E1%BB%87t)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    area maps
                  </a>
                </iframe>
              </div>
            </div>
            <div className="w-full">
              <div>
                <iframe
                  title="tanviet"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=3%20%C4%90%C6%B0%E1%BB%9Dng%20KCN%20%C3%94%20t%C3%B4%20Nguy%C3%AAn%20Kh%C3%AA,%20Nguy%C3%AAn%20Kh%C3%AA,%20%C4%90%C3%B4ng%20Anh,%20H%C3%A0%20N%E1%BB%99i+(X%C6%B0%E1%BB%9Fng%20Anode%20Nh%C3%B4m%20-%20T%C3%A2n%20Vi%E1%BB%87t)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    area maps
                  </a>
                </iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="hr border-[#979797] mt-12"></div>
        <p className="font-inter font-bold text-16px mt-6 mb-4 mt-4 react-text text-center md:float-left">
          {/* <img
            src="/assets/header/logo-large.webp"
            alt="logo"
            className="hidden lg:block h-[32px] object-contain"
          /> */}
        </p>
        <p className="font-inter font-bold text-16px mt-6 pb-4 mt-2 react-text text-center md:float-right">
          @TanVietJSC 2023. All rights reserved.
        </p>
      </div>
    </div>
  );
}
