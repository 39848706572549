import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const news = require("../data/news.json");

export const Section7 = () => {
  const newsData = _.cloneDeep(news);
  const { t } = useTranslation();
  const nav = useNavigate();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    function onScroll() {
      let distance = window.scrollY;
      if (distance > 0 && !isShow) {
        setIsShow(true);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="w-screen h-full">
      <div className="main-container py-10">
        <div>
          <div className="text-3xl font-inter-bold text-center">
            {t("News")}
          </div>
        </div>
        <div
          className={`grid md:grid-cols-3 self-center gap-8 mt-8  ${
            isShow && "team-appear-anim anim-delay-1"
          } opacity-0`}
        >
          {newsData.map((i, idx) => {
            return (
              <div
                className="w-full cursor-pointer py-2"
                key={`section7-${idx}`}
                onClick={() => {
                  nav(`/news/${i.id}`);
                }}
              >
                <div className="text-center self-center w-full relative rounded-xl ">
                  <img
                    src={`/assets/news/${i.banner}`}
                    alt="banner"
                    className="w-full mx-auto text-center self-center object-cover rounded-lg h-[300px]"
                  />
                </div>
                <div className="px-1 mt-2">
                  <div className="text-sm text-[#5A5A5A] italic">
                    {new Date(i.time).toLocaleDateString()}
                  </div>
                  <div className="text-lg mt-2 font-bold">
                    {t(`${i.title}`)}
                  </div>
                  <div className="text-md mt-1">{t(`${i.description}`)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
