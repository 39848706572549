import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import "reactjs-popup/dist/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Section1 } from "components/Section1";
import _ from "lodash";
import { useEffect, useState } from "react";

const news = require("../data/news.json");

export const NewList = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const nav = useNavigate();
  const newsData = _.cloneDeep(news);
  const [activeNew, setActive] = useState(null);

  useEffect(() => {
    const data = newsData.filter((i) => i.id == id);
    if (data[0]) {
      setActive(data[0]);
    } else {
      setActive(newsData[0]);
    }
  }, [id]);

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
      className={`flex flex-col pb-20`}
    >
      <div className="main-container mb-12">
        <div className="py-5 border-[#D3D5D7] border-b-2">
          <span
            className="cursor-pointer text-link"
            onClick={() => {
              nav("/");
            }}
          >
            {t("home")}
          </span>
          {" >"} {t("News")}
        </div>
        <div className="relative grid grid-cols-1 md:grid-cols-3 gap-y-6 md:gap-6 mt-10">
          <div className="col-span-1 bg-[#F3F4F5] h-full w-full">
            <div className="font-inter-bold bg-[#718b9a] p-4 text-white text-xl">
              {t("Most popular")}
            </div>
            <div className="mt-3 px-3">
              {newsData.map((i) => {
                if (i)
                  return (
                    <div
                      key={`image-news-${i.id}`}
                      className={`flex gap-4 mb-4 cursor-pointer`}
                      onClick={() => {
                        nav(`/news/${i.id}`);
                      }}
                    >
                      <img
                        src={`/assets/news/${i.banner}`}
                        alt="banner"
                        className="w-36 object-cover"
                      />
                      <div className="w-full">
                        <div className="font-inter-bold text-md">
                          {t(`${i.title}`)}
                        </div>
                        <div className="text-sm">
                          {new Date(i.time).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
          {activeNew ? (
            <div className="col-span-2 flex flex-col">
              <div className="w-full">
                <div className="flex gap-1 text-4xl mb-2">
                  {t(activeNew.title)}:
                </div>
                {/* <div>
                  <img
                    src={`/assets/news/${activeNew.banner}`}
                    alt="banner"
                    className="w-full mx-auto text-center self-center object-cover"
                  />
                </div> */}
                <div className="flex gap-1 text-md mb-10">
                  {t("Post at")}:
                  <div className="text-[#5A5A5A]">
                    {new Date(activeNew.time).toLocaleDateString()}
                  </div>
                </div>
                <RenderNews news={activeNew} />
              </div>
            </div>
          ) : (
            <div className="col-span-2 flex flex-col gap-6">
              {t("not_found_error")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RenderNews = ({ news }) => {
  const { t } = useTranslation();
  let newHtml = news.html;

  return (
    <div className="text-lg">
      {newHtml?.map((tagInfor) => {
        let tag = tagInfor.tag;
        switch (tag) {
          case "div":
            return <div className="my-1">{t(tagInfor.value)}</div>;
          case "img":
            return (
              <div>
                <img
                  src={`/assets/news/${tagInfor.value}`}
                  alt={tagInfor.title ?? ""}
                  className="max-w-[600px] w-full md:w-[60%] self-center my-4 text-center mx-auto"
                />
                {!!tagInfor.title && (
                  <div className="text-sm md:text-md text-center mb-8">
                    {t(tagInfor.title)}
                  </div>
                )}
              </div>
            );
          default:
            return <></>;
        }
      })}
    </div>
  );
};
