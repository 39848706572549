import { Section1 } from "components/Section1";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import { Pagination } from "semantic-ui-react";
const products = require("../data/product.json");
const category = require("../data/category.json");

export const ProductList = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [currentCate, setCate] = useState(category[0].category);
  const [smallCate, setSmallCate] = useState(null);
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const limit = 12;
  const queryString = window.location.hash?.replace("#/product?", "");

  useEffect(() => {
    let params = queryString.split("&");
    let cate = params[0]?.split("=")?.[1];
    let smallCate = params[1]?.split("=")?.[1];

    if (cate) {
      setCate(cate);
    }
    if (smallCate) {
      setSmallCate(smallCate);
    }

    console.log({ cate, smallCate });
  }, [window.location.search]);

  useEffect(() => {
    let data = products.filter((i) => i.category === currentCate);
    if (smallCate !== null) {
      data = data.filter((i) => i.category2 === smallCate);
    }
    setTotal(data.length);
    let start = (page - 1) * limit;
    data = data.slice(start, start + limit).filter((i) => !!i);
    setProductList(data);

    let section = document.getElementById("start");
    if (section) {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: section.offsetTop + 100,
      });
    }
  }, [currentCate, page, smallCate]);

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
      className={`flex flex-col`}
    >
      <div className="main-container pb-12">
        <div className="py-5 border-[#D3D5D7] border-b-2">
          <span
            className="cursor-pointer text-link"
            onClick={() => {
              nav("/");
            }}
          >
            {t("home")}
          </span>
          {" >"} {t("product")}
        </div>
        <div className="relative grid grid-cols-1 md:grid-cols-4 gap-y-6 md:gap-6 mt-10">
          <div className="col-span-1 bg-[#F3F4F5] h-full w-full">
            <div className="text-lg bg-[#446cb3] p-4 text-white uppercase">
              {t("Product list")}
            </div>
            <Category
              currentCate={currentCate}
              setCate={setCate}
              smallCate={smallCate}
              setSmallCate={setSmallCate}
            />
          </div>
          <div className="col-span-3 flex flex-col gap-6">
            <div className="bg-[#446cb3] text-lg p-4 px-8 text-white">
              {t(`${currentCate}_product_desc`)}
            </div>
            <div className="bg-[#F3F4F5] p-4 px-8 min-h-[800px]" id="start">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-6 gap-y-8">
                {productList?.length ? (
                  productList.map((i, idx) => {
                    return <Product data={i} idx={idx} />;
                  })
                ) : (
                  <div className="flex col-span-2 md:col-span-4 self-center">
                    <div className="min-h-[300px] flex self-center justify-center w-full">
                      {t("item_is_updating")}
                    </div>
                  </div>
                )}
              </div>

              <div className="my-3 mt-6 mx-auto w-full flex justify-center">
                <Pagination
                  onPageChange={(e, data) => {
                    setPage(data.activePage);
                  }}
                  className="mx-auto self-center"
                  totalPages={Math.ceil(total / limit)}
                  activePage={page}
                  siblingRange={1}
                  boundaryRange={1}
                  showEllipsis={false}
                  lastItem={null}
                  firstItem={null}
                  size={"mini"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Product = ({ data, idx }) => {
  let type = data.type;
  let length = data.quantity;
  let isOnly = data.is_only;
  const [currentIdx, setCurrentIdx] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentIdx(0);
  }, [data]);

  const onClickRight = () => {
    if (currentIdx + 1 > length - 1) {
      setCurrentIdx(0);
    } else {
      setCurrentIdx(currentIdx + 1);
    }
  };

  const onCLickLeft = () => {
    if (currentIdx - 1 < 0) {
      setCurrentIdx(length - 1);
    } else {
      setCurrentIdx(currentIdx - 1);
    }
  };

  return (
    <div className="cursor-pointer" key={`image-prod-${idx}`}>
      <div
        className="bg-white border-[1px] border-dashed border-[#1D4ED8] w-full relative product-container"
        style={{
          paddingTop: "50%",
          paddingBottom: "50%",
          height: 0,
        }}
      >
        <img
          src={`/assets/product/${type}${isOnly ? "" : `_${currentIdx}`}.webp`}
          alt={type}
          className="w-full h-full product-img"
        />
        {length > 1 && (
          <div className="absolute w-full flex justify-center gap-4 bottom-4 control-product">
            <img
              src="/assets/icon/left-arrow.webp"
              alt="left"
              className="w-6 h-6 cursor-pointer opacity-50"
              onClick={onCLickLeft}
            />
            <img
              src="/assets/icon/left-arrow.webp"
              alt="right"
              className="w-6 h-6 cursor-pointer opacity-50"
              style={{
                transform: "scale(-1, 1)",
              }}
              onClick={onClickRight}
            />
          </div>
        )}
      </div>
      <div className="font-inter-bold text-lg mt-1.5 px-1 w-full truncate text-center">
        {t(`${type}_title`)}
      </div>
      {/* <div className="px-1">{t(`${type}_description`)}</div> */}
    </div>
  );
};

export const Category = ({
  currentCate,
  smallCate,
  setCate,
  setSmallCate,
  isShowActive = true,
  isNav = false,
}) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  return (
    <>
      {category.map((bigCateData) => {
        let smallCateInfo = bigCateData.value;
        return (
          <div className="mt-2">
            <div
              className={`${
                currentCate === bigCateData.category &&
                smallCate === null &&
                isShowActive &&
                "active-category"
              } category big-cate`}
              onClick={() => {
                if (isNav) {
                  nav(`/product?cate=${bigCateData.category}`);
                } else {
                  setCate(bigCateData.category);
                  setSmallCate(null);
                }
              }}
            >
              {t(bigCateData.category)}
            </div>
            <div className="">
              {smallCateInfo.map((smallCateData) => {
                return (
                  <div
                    className={`${
                      smallCate === smallCateData.category &&
                      bigCateData.category === currentCate &&
                      "active-category"
                    } category cate-level2`}
                    onClick={() => {
                      if (isNav) {
                        nav(
                          `/product?cate=${bigCateData.category}&smallCate=${smallCateData.category}`
                        );
                      } else {
                        setCate(bigCateData.category);
                        setSmallCate(smallCateData.category);
                      }
                    }}
                  >
                    + {t(smallCateData.category)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
