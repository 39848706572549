import { Section1 } from "components/Section1";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import { Pagination } from "semantic-ui-react";
const products = require("../data/product.json");
const category = require("../data/category.json");

export const Contact = () => {
  const { t } = useTranslation();
  const nav = useNavigate();

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
      className={`flex flex-col main-container`}
    >
      <div className=" mb-12">
        <div className="py-5 border-[#D3D5D7] border-b-2">
          <span
            className="cursor-pointer text-link"
            onClick={() => {
              nav("/");
            }}
          >
            {t("home")}
          </span>
          {" >"} {t("contact")}
        </div>
        <div className="relative mt-10">
          <div className="h-full w-full">
            <div className="text-xl uppercase font-inter-bold">
              {t("contact")}
            </div>
            <div className="text-lg mt-4">
              <span className="font-inter-bold">{t("Headquarters")}</span>:{" "}
              {t("location_1")}
            </div>

            <div className="md:flex gap-10">
              <div className="flex gap-1 mt-5 text-lg">
                <span className="font-inter-bold">Hotline 1: </span>
                <div className="self-center text-left">
                  <FaPhone color="grey" />
                </div>
                <div
                  className="self-center text-left text-inter-bold cursor-pointer"
                  onClick={() => {
                    window.open("https://zalo.me/84913300803");
                  }}
                >
                  (+84)913.300.803
                </div>
              </div>

              <div className="flex gap-1 mt-5 text-lg">
                <span className="font-inter-bold">Hotline 2: </span>
                <div className="self-center text-left">
                  <FaPhone color="grey" />
                </div>
                <div
                  className="self-center text-left text-inter-bold cursor-pointer"
                  onClick={() => {
                    window.open("https://zalo.me/84912710480");
                  }}
                >
                  (+84)912.710.480
                </div>
              </div>
            </div>

            <div className="flex gap-1 mt-5 text-lg">
              <span className="font-inter-bold">Email: </span>
              <div className="self-center text-left">
                <FaEnvelope color="grey" />
              </div>
              <div className="self-center text-left text-inter-bold">
                tanviet.cpy@gmail.com
              </div>
            </div>

            <div className="mt-4 md:flex gap-24">
              <div className="">
                <div className="mt-4 text-lg">
                  <span className="font-inter-bold">{t("First factory")}</span>:{" "}
                  {t("first_factory_desc")}
                </div>
                <div className="flex gap-2 self-center mt-1 text-lg">
                  <img
                    src="/assets/icon/location.webp"
                    className="h-4 max-w-min translate-y-[5px]"
                    alt="location"
                  />
                  <div>
                    {t("Location")}: {t("location_1")}
                  </div>
                </div>
                <div className="mt-4">
                  <iframe
                    title="tanviet"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=5VC3+P42%20C%C3%B4ng%20ty%20CP%20v%C3%A0%20TM%20c%C6%A1%20kh%C3%AD%20T%C3%A2n%20Vi%E1%BB%87t,%20%C4%90%C3%B4ng%20Anh,%20H%C3%A0%20N%E1%BB%99i,%20Vietnam+(C%C3%B4ng%20ty%20CP%20v%C3%A0%20TM%20c%C6%A1%20kh%C3%AD%20T%C3%A2n%20Vi%E1%BB%87t)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  >
                    <a href="https://www.maps.ie/distance-area-calculator.html">
                      area maps
                    </a>
                  </iframe>
                </div>
              </div>
              <div>
                <div className="mt-4 text-lg">
                  <span className="font-inter-bold">{t("Second factory")}</span>
                  : {t("second_factory_desc")}
                </div>
                <div className="flex gap-2 self-center mt-1 text-lg">
                  <img
                    src="/assets/icon/location.webp"
                    className="h-4 max-w-min translate-y-[5px]"
                    alt="location"
                  />
                  <div>
                    {t("Location")}: {t("location_2")}
                  </div>
                </div>
                <div className="mt-4">
                  <iframe
                    title="tanviet"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=3%20%C4%90%C6%B0%E1%BB%9Dng%20KCN%20%C3%94%20t%C3%B4%20Nguy%C3%AAn%20Kh%C3%AA,%20Nguy%C3%AAn%20Kh%C3%AA,%20%C4%90%C3%B4ng%20Anh,%20H%C3%A0%20N%E1%BB%99i+(X%C6%B0%E1%BB%9Fng%20Anode%20Nh%C3%B4m%20-%20T%C3%A2n%20Vi%E1%BB%87t)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  >
                    <a href="https://www.maps.ie/distance-area-calculator.html">
                      area maps
                    </a>
                  </iframe>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
