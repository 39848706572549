import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import Slider from "react-slick";
const products = require("../../src/data/product.json");

export const Section2 = () => {
  const { t } = useTranslation();
  const productsData = _.cloneDeep(products);
  const [currentCate, setCategory] = useState(0);
  const [currentData, setCurrentData] = useState(productsData[0]);
  const [isShow, setIsShow] = useState(false);

  const customerImages = [
    "abb.webp",
    "hitachi.webp",
    "honda.webp",
    "showa.webp",
    "yamaha.webp",
    "t.webp",
    "kyb.webp",
    "nfk.webp",
    "tranit.webp",
  ];
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1200,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    setCurrentData(productsData[currentCate]);
  }, [currentCate]);

  useEffect(() => {
    setIsShow(true);
    // function onScroll() {
    //   let distance = window.scrollY;
    //   if (distance >= 0 && !isShow) {
    //     setIsShow(true);
    //   }
    // }
    // window.addEventListener("scroll", onScroll);
    // return () => {
    //   window.removeEventListener("scroll", onScroll);
    // };
  }, []);

  return (
    <div
      className="w-screen h-full relative bg-white mt-10"
      id="section2-start"
    >
      <div className="py-10 relative">
        <div className="bg-[#4E6E81]">
          <div className="grid md:grid-cols-2 gap-x-8 py-10 main-container">
            <div
              className={`text-white text-lg flex self-center flex-col text-story ${
                isShow && "story-appear-anim"
              } anim-delay-1 opacity-0`}
            >
              <div className="text-3xl mb-4 font-inter-bold">
                {t("Targeted customer")}
              </div>
              <div>{t("target_customer_desc_1")} </div>
              <div className="mt-2">{t("target_customer_desc_2")} </div>
              <div className="mt-4 font-bold text-xl text-stroke">
                {t("target_customer_slogan")}
              </div>
            </div>
            <div
              className="flex"
              style={{
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/image/slogan.webp"
                alt="slogan"
                className="h-4/5 self-center"
              />
            </div>
          </div>
        </div>
        <div className="justify-between mt-10">
          <div className="font-bold text-3xl font-inter-bold text-center w-full">
            {t("our_customer")}
          </div>
        </div>
        <Slider
          className={`w-full main-container pt-10 ${
            isShow && "smoke-appear"
          } anim-delay-1 opacity-0`}
          {...settings}
        >
          {customerImages.map((image, idx) => {
            return (
              <div key={`image-sec2-${idx}`}>
                <img
                  src={`/assets/client/origin/${image}`}
                  alt="customer"
                  className="w-full object-cover cursor-pointer card-client"
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
