import { useTranslation } from "react-i18next";
import { Dropdown, Flag } from "semantic-ui-react";
import i18n from "i18n";
export default function Language({ language, setLanguage }) {
  const { t } = useTranslation();
  function changeLanguage(lng) {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  }
  return (
    <div
      className="self-center h-full flex"
      style={{
        zIndex: 999,
      }}
    >
      <Dropdown
        trigger={
          <div
            className="text-white flex gap-2 relative pr-4 sm:pr-0 h-full self-center py-[15px]"
            onClick={() => console.log("click")}
          >
            <div className="font-inter capitalize w-max text-xs self-center md:text-xl">
              {t("language")}
            </div>
            <div className="self-center">
              {language === "vi" && <Flag name="vn" />}
              {language === "en" && <Flag name="england" />}
              {language === "ja" && <Flag name="jp" />}
              {language === "cn" && <Flag name="cn" />}
              {language === "kr" && <Flag name="kr" />}
            </div>
          </div>
        }
        direction="left"
        pointing
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              changeLanguage("vi");
            }}
            content={
              <p>
                <Flag name="vn" /> {t("Vietnamese")}
              </p>
            }
          />
          <Dropdown.Item
            onClick={() => {
              changeLanguage("en");
            }}
            content={
              <p>
                <Flag name="england" /> {t("English")}
              </p>
            }
          />
          <Dropdown.Item
            onClick={() => {
              changeLanguage("ja");
            }}
            content={
              <p>
                <Flag name="jp" /> {t("Japanese")}
              </p>
            }
          />
          <Dropdown.Item
            onClick={() => {
              changeLanguage("cn");
            }}
            content={
              <p>
                <Flag name="cn" /> {t("Chinese")}
              </p>
            }
          />
          <Dropdown.Item
            onClick={() => {
              changeLanguage("kr");
            }}
            content={
              <p>
                <Flag name="kr" /> {t("Korean")}
              </p>
            }
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
