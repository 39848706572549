import { Link } from "react-scroll";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import Language from "components/Language";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  FaEnvelope,
  FaFacebook,
  FaPhone,
  FaMapMarkerAlt,
  FaSearchLocation,
  FaHome,
} from "react-icons/fa";
import { Menu } from "semantic-ui-react";

var Scroll = require("react-scroll");
var Events = Scroll.Events;
export default function Header(props) {
  const [optical, setOptical] = useState(0);
  const [elements, setElements] = useState([]);
  const [scrolling, setScrolling] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const refMenuContent = useRef();
  const { t } = useTranslation();
  const nav = useNavigate();
  const params = useParams();

  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(refMenuContent, () => {
    process.nextTick(() => {
      if (!showMenu) {
        return;
      }
      setShowMenu(false);
    });
  });

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      setScrolling(true);
    });

    Events.scrollEvent.register("end", function (to, element) {
      setScrolling(false);
    });
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <div
      className="w-full md:py-1 pb-2 bg-[#F5F5F5] -top-[5px]"
      style={{ zIndex: 100 }}
    >
      <div className="bg-[#F5F5F5] md:py-2 w-full hidden md:flex justify-between main-container">
        <div className="flex gap-5">
          <div className="flex gap-2 self-center text-[#446Cb3]">
            <div className="self-center text-left">
              <FaEnvelope color="grey" />
            </div>
            <div className="self-center text-left text-inter-bold">
              tanviet.cpy@gmail.com
            </div>
          </div>
          <div className="flex gap-2 self-center text-[#446Cb3]">
            <div className="self-center text-left">
              <FaPhone color="grey" />
            </div>
            <div
              className="self-center text-left text-inter-bold cursor-pointer"
              onClick={() => {
                window.open("https://zalo.me/84913300803");
              }}
            >
              (+84)913.300.803
            </div>
          </div>
          <div className="flex gap-2 self-center text-[#446Cb3]">
            <div className="self-center text-left">
              <FaPhone color="grey" />
            </div>
            <div
              className="self-center text-left text-inter-bold cursor-pointer"
              onClick={() => {
                window.open("https://zalo.me/84912710480");
              }}
            >
              (+84)912.710.480
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex gap-2 self-center text-[#446Cb3] cursor-pointer">
            <div className="self-center text-left">
              <FaFacebook color="#446Cb3" />
            </div>
            <div className="self-center text-left text-inter-bold">
              Tân Việt Group
            </div>
          </div>
        </div>
      </div>

      <div className="md:py-2 bg-white w-full bg-white">
        <div className="main-container flex justify-center gap-20">
          <img
            onClick={() => {
              nav("/");
            }}
            src="/assets/header/logo.png"
            alt="logo"
            className="hidden lg:block h-[72px] object-contain self-center cursor-pointer"
          />
          <div className="self-center mx-auto w-full">
            <div className="hidden md:block uppercase text-[24px] w-full font-inter-bold mb-1 text-[#C00000]">
              {t("company_name_1")}
            </div>
            <div className="hidden md:flex gap-12 text-[15px]">
              <div>
                <div className="flex gap-1">
                  <FaHome color="#DB241A" className="translate-y-[3px]" />
                  {t("first_factory_desc")}
                </div>
                <div className="flex gap-1">
                  <FaMapMarkerAlt
                    color="#DB241A"
                    className="translate-y-[3px]"
                  />
                  <div>
                    {t("Location")}: {t("location_1")}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex gap-1">
                  <FaHome color="#DB241A" className="translate-y-[3px]" />
                  {t("second_factory_desc")}
                </div>
                <div className="flex gap-1">
                  <FaMapMarkerAlt
                    color="#DB241A"
                    className="translate-y-[3px]"
                  />
                  <div>
                    {t("Location")}: {t("location_2")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#446Cb3]">
        <div className="hidden md:block main-container">
          <Menu
            style={{
              background: "#446cb3",
              borderRadius: "0",
            }}
            className="flex"
          >
            <Menu.Item
              className="menu-item-container"
              active={["", "/"].includes(path)}
              onClick={() => {
                nav("/");
              }}
            >
              <div className="menu-item">{t("home")}</div>
            </Menu.Item>
            <Menu.Item
              className="menu-item-container"
              active={["about", "/about"].includes(path)}
              onClick={() => {
                nav("/about");
              }}
            >
              <div className="menu-item">{t("about")}</div>
            </Menu.Item>
            <Menu.Item
              className="menu-item-container"
              active={["product", "/product"].includes(path)}
              onClick={() => {
                nav("/product");
              }}
            >
              <div className="menu-item">{t("product")}</div>
            </Menu.Item>
            <Menu.Item
              className="menu-item-container"
              active={["news", "/news"].includes(path)}
              onClick={() => {
                nav("/news");
              }}
            >
              <div className="menu-item">{t("News")}</div>
            </Menu.Item>
            <Menu.Item
              className="menu-item-container"
              active={["contact", "/contact"].includes(path)}
              onClick={() => {
                nav("/contact");
              }}
            >
              <div className="menu-item">{t("contact")}</div>
            </Menu.Item>
            <Menu.Menu
              inverted={true.toString()}
              position="right"
              key="rightmenu"
            >
              <div className="flex self-center">
                <Language
                  language={props.language}
                  setLanguage={props.setLanguage}
                />
              </div>
            </Menu.Menu>
          </Menu>
        </div>

        <div className="h-[34px] flex md:hidden relative flex-row items-center justify-center w-screen bg-cover bg-center">
          <div
            className="dropdown absolute flex top-0 left-0 justify-center items-center"
            style={{
              zIndex: "99",
            }}
          >
            {!showMenu && (
              <img
                className="ml-3 self-center"
                src="/assets/header/menu.svg"
                alt="menu"
                onClick={() => {
                  console.log(1);
                  if (showMenu) return;
                  setShowMenu(true);
                }}
              />
            )}
            {showMenu && (
              <div
                ref={refMenuContent}
                className="dropdown-content w-screen h-screen"
                style={{
                  background: "black",
                }}
              >
                <div className="flex flex-col items-center text-inter">
                  <div className="flex justify-between w-full">
                    <img
                      src="/assets/header/logo-large-news.webp"
                      alt="logo"
                      className="h-[18px] object-contain self-center"
                      style={{
                        zIndex: "1",
                      }}
                    />
                    <div
                      className="text-white right-10 text-2xl cursor-pointer bg-white rounded-xl cursor-pointer"
                      onClick={() => {
                        setShowMenu(false);
                      }}
                    >
                      <img
                        src="/assets/icon/close.webp"
                        alt="close"
                        className="h-6 text-white"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col text-lg font-bold gap-2 w-full select-none mt-4">
                    <Menu.Item
                      className="menu-item-container"
                      active={["", "/"].includes(path)}
                      onClick={() => {
                        nav("/");
                        setShowMenu(false);
                      }}
                    >
                      <div className="menu-item">{t("home")}</div>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-item-container"
                      active={["about", "/about"].includes(path)}
                      onClick={() => {
                        nav("/about");
                        setShowMenu(false);
                      }}
                    >
                      <div className="menu-item">{t("about")}</div>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-item-container"
                      active={["product", "/product"].includes(path)}
                      onClick={() => {
                        nav("/product");
                        setShowMenu(false);
                      }}
                    >
                      <div className="menu-item">{t("product")}</div>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-item-container"
                      active={["news", "/news"].includes(path)}
                      onClick={() => {
                        nav("/news");
                        setShowMenu(false);
                      }}
                    >
                      <div className="menu-item">{t("News")}</div>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-item-container"
                      active={["contact", "/contact"].includes(path)}
                      onClick={() => {
                        nav("/contact");
                        setShowMenu(false);
                      }}
                    >
                      <div className="menu-item">{t("contact")}</div>
                    </Menu.Item>
                    <div
                      className="h-[1px] w-full "
                      style={{ backgroundColor: "#50481E" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {!showMenu && (
            <img
              src="/assets/header/logo-large-news.webp"
              alt="logo"
              className="h-[20px] block md:hidden object-contain mx-auto self-center w-full absolute"
              style={{
                zIndex: "1",
                filter: "brightness(0) invert(1)",
              }}
            />
          )}
          <div
            className="right-0 absolute flex items-center justify-center h-full"
            style={{
              zIndex: "99",
            }}
          >
            {!showMenu && (
              <Language
                language={props.language}
                setLanguage={props.setLanguage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
