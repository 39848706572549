import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { Layout } from "Layout";
import { ProductList } from "layout/ProductList";
ReactGA.initialize("UA-214554139-1");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/*" element={<Layout />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
